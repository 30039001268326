<div class="flex gap-0 items-center h-full">
  @if(!isSameTimezone()){
  <div class="flex flex-col min-w-28 h-full rounded p-2 items-center justify-center bg-slate-100"
    [matTooltip]="localTimeZoneGMT">
    <div class="text-xs uppercase font-sans font-semibold">{{'Hora Local' | transloco}}</div>
    <div class="text-xs text-center flex justify-center items-center gap-1 w-full">
      <mat-icon svgIcon="heroicons_outline:clock" class="icon-size-3"></mat-icon>
      {{localTime}}
    </div>
  </div>
  }

  <div class="flex flex-col min-w-28 h-full rounded p-2 items-center justify-center">
    <div class="text-xs uppercase font-sans font-semibold">{{'Portugal' | transloco}}</div>
    <div class="text-xs text-center flex justify-center items-center gap-1 w-full">
      <mat-icon svgIcon="heroicons_outline:clock" class="icon-size-3"></mat-icon>
      {{lisbonTime }}
    </div>
  </div>

</div>
