import { EnvironmentProviders, Provider, importProvidersFrom } from "@angular/core";
import { environment } from "environments/environment";
import { Storage } from '@targx/libs/storage';
import { ApolloModule, APOLLO_OPTIONS, APOLLO_NAMED_OPTIONS } from 'apollo-angular';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';


export const createApollo = (httpLink: HttpLink) => {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: '*/*',
    },
  }));

  const auth = setContext((operation, context) => {
    const token = Storage.get(environment.KEY_LOCAL_STORAGE);

    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
  });

  const link = ApolloLink.from([
    basic,
    auth,
    httpLink.create({
      uri: environment.graphql,
    }),
  ]);

  const cache = new InMemoryCache();

  return {
    link,
    cache,
  };
};

export const createNamedApollo = (httpLink: HttpLink) => {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: '*/*',
    },
  }));

  const auth = setContext((operation, context) => {
    const token = Storage.get(environment.KEY_LOCAL_STORAGE);

    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
  });

  const secondLink = ApolloLink.from([
    basic,
    auth,
    httpLink.create({
      uri: environment.doctors,
    }),
  ]);

  const thirdLink = ApolloLink.from([
    basic,
    auth,
    httpLink.create({
      uri: environment.management,
    }),
  ]);

  const cache = new InMemoryCache();

  return {
    doctors: {
      name: 'doctors',
      link: secondLink,
      cache
    },
    managment: {
      name: 'managment',
      link: thirdLink,
      cache
    }
  };
};

export const providerGraphql = (): Array<Provider | EnvironmentProviders> => {

  return [
    importProvidersFrom(ApolloModule),
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      deps: [HttpLink],
      useFactory: createNamedApollo
    }
  ];
};
