import { Injectable } from '@angular/core';
import { Paginated } from '@core/types/paged-type';
import { Apollo } from 'apollo-angular';
import { of, ReplaySubject, switchMap } from 'rxjs';
import { GET_LANGUAGES } from '../graphql/misc';

export class LanguageType {
  [key: string]: any;
}


class PaginatedLanguageFindAll extends Paginated(LanguageType) { }


@Injectable({
  providedIn: 'root'
})
export class LanguagesService {


  constructor(private readonly _apollo: Apollo) { }

  private _items$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _items: any;

  get items$() {
    return this._items$.asObservable();
  }

  get items() {
    return this._items;
  }

  set items(value) {
    this._items = value;
    this._items$.next(value);
  }

  findAll(args?: { skip?: number; take?: number; where?: any; orderBy?: any }) {
    return this._apollo
      .watchQuery<{ LanguagesFindMany: PaginatedLanguageFindAll }>({
        query: GET_LANGUAGES,
        variables: {
          ...args,
        },
        fetchPolicy: 'cache-and-network',
      })
      .valueChanges.pipe(
        switchMap(result => {
          if (result.errors) throw new Error(result.errors[0].message);

          const permissions = result.data.LanguagesFindMany.items;

          this.items = permissions;
          return of(permissions);
        })
      );
  }
}
