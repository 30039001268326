/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

const all: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Management',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/management'
  },
  {
    id: 'example',
    title: 'My 3Ms',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/my3ms'
  },
  {
    id: 'example',
    title: 'POS',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/pos'
  },
  {
    id: 'example',
    title: 'Doctor',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/doctor'
  }
];

const navigation: FuseNavigationItem[] = [
  {
    id: 'main',
    title: 'Main',
    subtitle: 'Overview',
    type: 'group',
    icon: 'heroicons_outline:home',
    children: [
      {
        id: 'main.dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:rectangle-group',
        link: '/management/dashboard',
      },
      {
        id: 'main.appointments',
        title: 'Appointments',
        type: 'basic',
        icon: 'heroicons_outline:calendar-days',
        link: '/management/appointments',
      },
      {
        id: 'dashboards.doctors',
        title: 'Doctors',
        type: 'basic',
        icon: 'heroicons_outline:identification',
        link: '/management/employees',
      },
      {
        id: 'dashboards.patients',
        title: 'Clients',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/management/clients',
      },
    ],
  },

  {
    id: 'clinic',
    title: 'Clinic Centers',
    subtitle: 'Overview',
    type: 'group',
    icon: 'heroicons_outline:home',
    children: [
      {
        id: 'clinic.clinics',
        title: 'Clinics',
        type: 'basic',
        icon: 'heroicons_outline:home-modern',
        link: '/management/poles',
      },
      {
        id: 'clinic.rooms',
        title: 'Rooms',
        type: 'basic',
        icon: 'feather:grid',
        link: '/management/rooms',
      },
      {
        id: 'clinic.specialities',
        title: 'Specialities',
        type: 'basic',
        icon: 'feather:activity',
        link: '/management/specialities',
      },
    ],
  },

  {
    id: 'financial',
    title: 'Finance',
    subtitle: 'Overview',
    type: 'group',
    icon: 'heroicons_outline:home',
    children: [
      {
        id: 'financial.billing',
        title: 'Billing Record',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-document-check',
        link: '/management/billing',
        disabled: true,
      },
      {
        id: 'financial.doctors',
        title: 'Doctor Earning',
        type: 'basic',
        icon: 'heroicons_outline:banknotes',
        link: '/management/doctor-earnings',
        disabled: false,
      },
      {
        id: 'financial.clinic',
        title: 'Clinic Earning',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/management/clinic-earning',
        disabled: false,
      },
    ],
  },

  {
    id: 'system',
    title: 'System',
    subtitle: 'Overview',
    type: 'group',
    icon: 'heroicons_outline:home',
    children: [
      {
        id: 'system.settings',
        title: 'Settings',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-document-check',
        link: '/management/settings',
        disabled: true,
      },
      // {
      //   id: 'clinic.rooms',
      //   title: 'Rooms',
      //   type: 'basic',
      //   icon: 'heroicons_outline:banknotes',
      //   link: '/management/rooms',
      //   disabled: true,
      // },
      // {
      //   id: 'clinic.specializations',
      //   title: 'Specializations',
      //   type: 'basic',
      //   icon: 'heroicons_outline:chart-pie',
      //   link: '/management/specilizations',
      //   disabled: true,
      // },
    ],
  },
];


export const defaultNavigation: FuseNavigationItem[] = navigation;
export const compactNavigation: FuseNavigationItem[] = navigation;
export const futuristicNavigation: FuseNavigationItem[] = navigation;
export const horizontalNavigation: FuseNavigationItem[] = navigation;
