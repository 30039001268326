<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
@if (isScreenSmall) {
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
    [navigation]="navigation.default" [opened]="false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex h-20 items-center px-8 pt-6">
            <img class="w-24" src="images/3ms/logotipo-3ms.png" alt="Logo image" />
        </div>
    </ng-container>
</fuse-vertical-navigation>
}

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col items-center bg-gray-200 dark:bg-card">
    <!-- Header -->
    <div
        class="relative z-49 flex h-16 w-full flex-0 flex-col justify-center overflow-hidden shadow dark:shadow-none sm:h-20 md:h-36 print:hidden">
        <!-- Top bar -->
        <div class="dark relative flex w-full flex-auto justify-center bg-gray-800 px-4 dark:bg-gray-900 md:px-8">
            <div class="flex h-16 w-full max-w-360 items-center sm:h-20">
                <!-- Logo -->
                @if (!isScreenSmall) {
                <div class="flex items-center">
                    <img class="w-24" src="images/3ms/logotipo-3ms.png" alt="Logo image" />
                </div>
                }
                <!-- Navigation toggle button -->
                @if (isScreenSmall) {
                <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                    <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
                </button>
                }
                <!-- Components -->
                <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
                    <languages></languages>
                    <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
                    <search [appearance]="'bar'"></search>
                    <shortcuts></shortcuts>
                    <messages></messages>
                    <notifications></notifications>
                    <button class="lg:hidden" mat-icon-button (click)="quickChat.toggle()">
                        <mat-icon [svgIcon]="
                                'heroicons_outline:chat-bubble-left-right'
                            "></mat-icon>
                    </button>
                    <user></user>
                </div>
            </div>
        </div>
        <!-- Bottom bar -->
        @if (!isScreenSmall) {
        <div class="bg-card flex flex-auto justify-center px-4 dark:bg-gray-700 md:px-8">
            <div class="relative flex h-16 w-full max-w-360 items-center">
                <fuse-horizontal-navigation class="-mx-4" [name]="'mainNavigation'"
                    [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
            </div>
        </div>
        }
    </div>

    <!-- Content -->
    <div class="flex w-full flex-auto justify-center sm:p-6 md:p-8">
        <div
            class="bg-default flex w-full flex-auto flex-col sm:max-w-360 sm:overflow-hidden sm:rounded-lg sm:shadow-lg">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            @if (true) {
            <router-outlet></router-outlet>
            }
        </div>
    </div>

    <!-- Footer -->
    <div class="bg-card relative z-49 flex w-full flex-0 justify-center border-t px-6 md:px-8 print:hidden">
        <div class="flex h-14 w-full max-w-360 items-center sm:h-20">
            <span class="text-secondary font-medium">Fuse &copy; {{ currentYear }}</span>
        </div>
    </div>
</div>

<!-- Quick chat -->
<quick-chat #quickChat="quickChat"></quick-chat>
