<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
@if (isScreenSmall) {
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
    [navigation]="navigation.default" [opened]="false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex h-20 items-center px-8 pt-6">
            <img class="w-24" src="images/3ms/logotipo-3ms.png" alt="Logo image" />
        </div>
    </ng-container>
</fuse-vertical-navigation>
}

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
    <!-- Header -->
    <div
        class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none sm:h-20 md:px-6 print:hidden">
        @if (!isScreenSmall) {
        <!-- Logo -->
        <div class="mx-2 flex items-center lg:mr-8">
            <div class="hidden lg:flex">
                <img class="w-24 dark:hidden" src="images/3ms/logotipo-3ms.png" />
                <img class="hidden w-24 dark:flex" src="images/3ms/logotipo-3ms.png" />
            </div>
            <img class="flex w-8 lg:hidden" src="images/3ms/cropped-favicon_Prancheta-1-270x270.png" />
        </div>
        <!-- Horizontal navigation -->
        <fuse-horizontal-navigation class="mr-2" [name]="'mainNavigation'"
            [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
        }
        <!-- Navigation toggle button -->
        @if (isScreenSmall) {
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        }
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>
            <notifications></notifications>
            <button class="lg:hidden" mat-icon-button (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex w-full flex-auto flex-col">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
        <router-outlet></router-outlet>
        }
    </div>

    <!-- Footer -->
    <div
        class="bg-card relative z-49 flex h-14 w-full flex-0 items-center border-t px-4 dark:bg-transparent sm:h-20 md:px-6 print:hidden">
        <span class="text-secondary font-medium">Fuse &copy; {{ currentYear }}</span>
    </div>
</div>

<!-- Quick chat -->
<quick-chat #quickChat="quickChat"></quick-chat>
