import { EnvironmentProviders, Provider } from "@angular/core";
import { provideIcons } from "@core/icons/icons.provider";

export const providerUploadTargx = (): Array<Provider | EnvironmentProviders> => {

  const providers: Array<Provider | EnvironmentProviders> = [


    // Icons
    provideIcons(),

  ];

  return providers;
};
