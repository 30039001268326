import gql from 'graphql-tag';

export const GET_COUNTRIES = gql`query CountryFindMany($where: CountryWhereInput, $take: Int, $skip: Int, $orderBy: [CountryOrderByWithRelationInput!]) {
  CountryFindMany(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
    items {
      id
      isActive
      flag
      iso2
      iso3
      locale
      name
    }
    meta {
      totalPages
      total
      pageSize
      page
    }
  }
}`;

export const GET_LANGUAGES = gql`
query LanguagesFindMany($where: LanguageWhereInput, $take: Int, $skip: Int, $orderBy: [LanguageOrderByWithRelationInput!]) {
  LanguagesFindMany(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
    items {
      id
      code
      isActive
      iso2
      name
    }
    meta {
      page
      pageSize
      total
      totalPages
    }
  }
}`;
