import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-clock',
  standalone: true,
  imports: [
    TranslocoModule,
    MatIconModule,
    MatTooltipModule
  ],
  templateUrl: './clock.component.html',
  styleUrl: './clock.component.scss'
})
export class ClockComponent implements OnInit {

  defatultTimezone = 'Europe/Lisbon';

  lisbonTime = DateTime.local().setZone('Europe/Lisbon').toLocaleString(DateTime.TIME_WITH_SECONDS);
  localTime = DateTime.local().toLocaleString(DateTime.TIME_WITH_SECONDS);

  localTimeZoneGMT = new Date().toTimeString().slice(9);
  localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.updateClock();
  }
  /**
   * Update the clock every second
   */
  updateClock(): void {
    setInterval(() => {
      this.lisbonTime = DateTime.local().setZone('Europe/Lisbon').toLocaleString(DateTime.TIME_WITH_SECONDS);
      this.localTime = DateTime.local().toLocaleString(DateTime.TIME_WITH_SECONDS);
    }, 1000);
  }

  /**
   * Check if the local timezone is the same as the Lisbon timezone
   */
  isSameTimezone(): boolean {
    return this.defatultTimezone === this.localTimeZone;
  }
}
