import { Injectable } from '@angular/core';
import { Paginated } from '@core/types/paged-type';
import { Apollo } from 'apollo-angular';
import { of, ReplaySubject, switchMap } from 'rxjs';
import { GET_COUNTRIES } from '../graphql/misc';

export class CountryType {
  [key: string]: any;
}


class PaginatedCountriesFindAll extends Paginated(CountryType) { }


@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private readonly _apollo: Apollo) { }

  private _countries$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _countries: any;

  get countries$() {
    return this._countries$.asObservable();
  }

  get countries() {
    return this._countries;
  }

  set countries(value) {
    this._countries = value;
    this._countries$.next(value);
  }

  findAll(args?: { skip?: number; take?: number; where?: any; orderBy?: any }) {
    return this._apollo
      .watchQuery<{ CountryFindMany: PaginatedCountriesFindAll }>({
        query: GET_COUNTRIES,
        variables: {
          ...args,
        },
        fetchPolicy: 'cache-and-network',
      })
      .valueChanges.pipe(
        switchMap(result => {
          if (result.errors) throw new Error(result.errors[0].message);

          const permissions = result.data.CountryFindMany.items;

          this.countries = permissions;
          return of(permissions);
        })
      );
  }
}
